import React from 'react';
import { MessageDiv, StyledArticle, StyledFileContainer } from '../../styles';

import { Layout } from '../../components/Layout';
import { StyledH2, StyledTable } from '../../styles/common';
import { SEO } from '../../components/SEO';

export function Head() {
	return (
		<SEO
			title="Wynajem taboru autobusowego i tramwajowego"
			description="Świadczenie usług transportowych."
		/>
	);
}

function PrzewozyPage() {
	return (
		<Layout>
			<StyledArticle>
				<StyledH2 as="h1" color="info">
					Wynajem taboru autobusowego i tramwajowego
				</StyledH2>
				<MessageDiv>
					<p>Świadczenie usług warsztatowych przez Spółkę na zewnątrz.</p>
					<h3>Cena 1 roboczogodziny na usługi warsztatowe</h3>
					<StyledTable>
						<tr>
							<th>L.p.</th>
							<th>Cena netto</th>
							<th>Cena brutto</th>
						</tr>
						<tr>
							<td rowSpan={2}>1.</td>
							<td colSpan={2}>
								Stacja Obsługi
								<br />
								ul. Inowrocławska 11
								<br />
								Kontakt: tel.: (52) 324-94-50
							</td>
						</tr>
						<tr>
							<td className="right">200,00 zł</td>
							<td>246,00 zł</td>
						</tr>
						<tr>
							<td rowSpan={2}>2.</td>
							<td colSpan={2}>
								Dział Napraw i Remontów Tramwajów
								<br />
								ul. Toruńska 278
								<br />
								Kontakt: tel.: (52) 324-94-70
							</td>
						</tr>
						<tr>
							<td className="right">200,00 zł</td>
							<td>246,00 zł</td>
						</tr>
						<tr>
							<td rowSpan={2}>3.</td>
							<td colSpan={2}>
								Wydział Infrastruktury Torowo-Sieciowej
								<br />
								ul. Toruńska 278
								<br />
								Kontakt: tel.: (52) 324-94-75
							</td>
						</tr>
						<tr>
							<td className="right">120,00 zł</td>
							<td>147,60 zł</td>
						</tr>
					</StyledTable>
					<p>
						Do ustalonej ceny netto należy doliczyć podatek „VAT” wg
						obowiązujących stawek.
					</p>
					<p>
						Cenę brutto stosuje się w przypadku osób fizycznych nieprowadzących
						działalności gospodarczej.
					</p>
				</MessageDiv>
			</StyledArticle>
		</Layout>
	);
}

export default PrzewozyPage;
